import React, { useEffect, useState } from "react";
import { Link, useLoaderData, useLocation } from "react-router-dom";
import Sidebar from "../Partials/Sidebar/Sidebar";
import { db } from "../../firebase/firebase";
import { collection, getDocs, query, where, Timestamp, doc, updateDoc, serverTimestamp, orderBy } from "firebase/firestore";
import ReactPaginate from "react-paginate";
import moment from "moment";
import emailjs from 'emailjs-com';
import Swal from "sweetalert2";


const MyAppointments = () => {
  const [appointments, setAppointments] = useState([]); // Store all appointments
  const [filteredAppointments, setFilteredAppointments] = useState([]); // Store filtered appointments
  const [statusFilter, setStatusFilter] = useState(); // Store filtered appointments
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [appointmentsPerPage] = useState(5); // Appointments per page

  const appointmentsRef = collection(db, "appointments");

  // async function fetchAppointmentsByQuery(status) {
  //   try {
  //     let q = appointmentsRef;

  //     if (status) {
  //       q = query(appointmentsRef, where("status", "==", status));
  //       if (status === "today") {
  //         const now = new Date();
  //         const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  //         const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

  //         const startTimestamp = Timestamp.fromDate(startOfDay);
  //         const endTimestamp = Timestamp.fromDate(endOfDay);

  //         q = query(
  //           appointmentsRef,
  //           where("date", ">=", startTimestamp),
  //           where("date", "<=", endTimestamp)
  //         );
  //       }
  //     }

  //     const querySnapshot = await getDocs(q);
  //     const fetchedAppointments = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     setAppointments(fetchedAppointments);
  //     setFilteredAppointments(fetchedAppointments.slice(0, appointmentsPerPage));
  //   } catch (error) {
  //     console.error("Error fetching appointments by query:", error);
  //   }
  // }

  const location = useLocation(); // Get the current location (URL)

  const getStatusFromQuery = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('status');  // Get 'status' from query string (e.g., ?status=today)
  };

  // Function to fetch appointments by query
  const fetchAppointmentsByQuery = async (status) => {
    try {
      let q = appointmentsRef;

      // Add filtering based on status, if provided
      if (status) {
        q = query(appointmentsRef, where("status", "==", status));
        if (status === "today") {
          const now = new Date();
          const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
          const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

          const startTimestamp = Timestamp.fromDate(startOfDay);
          const endTimestamp = Timestamp.fromDate(endOfDay);

          q = query(
            appointmentsRef,
            where("date", ">=", startTimestamp),
            where("date", "<=", endTimestamp)
          );
        }
      } else {
        // Order by date by default if no specific filter is provided
        q = query(appointmentsRef, orderBy("date", "asc"));
      }

      const querySnapshot = await getDocs(q);
      const fetchedAppointments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Group appointments by their status
      const groupedAppointments = fetchedAppointments.reduce((acc, appointment) => {
        const { status } = appointment;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(appointment);
        return acc;
      }, {});

      // Order appointments by date within each status group
      Object.keys(groupedAppointments).forEach((status) => {
        groupedAppointments[status].sort((a, b) => a.date.seconds - b.date.seconds);
      });

      // Convert grouped data into a flat array with grouping
      const orderedAppointments = [];
      ["pending", "accepted", "finished", "cancelled", "rejected"].forEach((status) => {
        if (groupedAppointments[status]) {
          orderedAppointments.push(...groupedAppointments[status]);
        }
      });

      setAppointments(orderedAppointments);
      setFilteredAppointments(orderedAppointments.slice(0, appointmentsPerPage));
    } catch (error) {
      console.error("Error fetching appointments by query:", error);
    }
  };


  const handleFilterChange = (e) => {
    setStatusFilter(e.target.value); // Update the filter state
    setCurrentPage(0); // Reset to the first page when filter changes
    fetchAppointmentsByQuery(e.target.value);
  };

  const handlePageClick = (selectedPage) => {
    const newPage = selectedPage.selected;
    const start = newPage * appointmentsPerPage;
    const end = start + appointmentsPerPage;

    setCurrentPage(newPage);
    setFilteredAppointments(appointments.slice(start, end));
  };


  const emailSubject = "Appointment Update From Witcare health";


  const acceptAppointmentHandler = async (docId, appID, email1, name1) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to accept this appointment!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Accept it!",
      });

      if (result.isConfirmed) {
        const docRef = doc(db, "appointments", docId);
        const status = "accepted";

        // Update the document in Firestore
        await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
        var message = `We have send this mail to inform you that your appointment ${appID} is Accepted.`
        const formdata = { subject: emailSubject, email: email1, name: name1, message: message };
        fetchAppointmentsByQuery(statusFilter)
        sendEmail(formdata);
        setAppointments((prevAppointments) => {
          return prevAppointments.map((appointment) =>
            appointment.id == docId
              ? { ...appointment, status: "accepted" }  // Update the status to "accepted"
              : appointment
          );
        });


        // Show success message
        Swal.fire({
          title: "Accepted!",
          text: "The appointment has been accepted.",
          icon: "success",
        });
      }
    } catch (error) {
      console.log("Error in acceptAppointmentHandler:", error);
    }
  };

  const handleReject = async (docId, appID, email1, name1) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to reject this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
    });
    if (result.isConfirmed) {
      const docRef = doc(db, "appointments", docId);
      const status = "rejected";

      // Update the document in Firestore
      await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
      var message = `We have send this mail to inform you that your appointment ${appID} is Rejected.`
      const formdata = { subject: emailSubject, email: email1, name: name1, message: message };
      fetchAppointmentsByQuery(statusFilter)
      sendEmail(formdata);

      setAppointments((prevAppointments) => {
        return prevAppointments.map((appointment) =>
          appointment.id == docId
            ? { ...appointment, status: "rejected" }  // Update the status to "accepted"
            : appointment
        );
      });

      Swal.fire({
        title: "Rejected!",
        text: "The appointment has been reject.",
        icon: "success",
      });
    }
  };

  const handleFinish = async (docId, appID, email1, name1) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to finish this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, finish it!",
    });
    if (result.isConfirmed) {
      const docRef = doc(db, "appointments", docId);
      const status = "finished";

      await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
      var message = `We have send this mail to inform you that your appointment ${appID} is Finished.`;
      const formdata = { subject: emailSubject, email: email1, name: name1, message: message };
      fetchAppointmentsByQuery(statusFilter)
      sendEmail(formdata);
      setAppointments((prevAppointments) => {
        return prevAppointments.map((appointment) =>
          appointment.id == docId
            ? { ...appointment, status: "finished" }  // Update the status to "accepted"
            : appointment
        );
      });

      Swal.fire({
        title: "Finished!",
        text: "The appointment has been finished.",
        icon: "success",
      });
    }
  };

  const handleCancel = async (docId, appID, email1, name1) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    });
    if (result.isConfirmed) {
      const docRef = doc(db, "appointments", docId);
      const status = "cancelled";

      await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
      var message = `We have send this mail to inform you that your appointment ${appID} is Cancelled.`
      const formdata = { subject: emailSubject, email: email1, name: name1, message: message };
      fetchAppointmentsByQuery(statusFilter)
      sendEmail(formdata);
      // setAppointment({ ...appointment, status });
      setAppointments((prevAppointments) => {
        return prevAppointments.map((appointment) =>
          appointment.id == docId
            ? { ...appointment, status: "cancelled" }  // Update the status to "accepted"
            : appointment
        );
      });
      Swal.fire({
        title: "Cancelled!",
        text: "The appointment has been cancelled.",
        icon: "success",
      });
    }
  };




  const sendEmail = (formData) => {
    emailjs.send(
      process.env.REACT_APP_SERVICE_ID,    // Replace with your EmailJS Service ID
      process.env.REACT_APP_TEMPLATE_ID_STATUS,   // Replace with your EmailJS Template ID
      formData,             // Data from the form fields
      process.env.REACT_APP_PUBLIC_KEY     // Replace with your EmailJS Public Key
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        // alert("Email sent successfully!");
      })
      .catch((error) => {
        console.error('FAILED...', error);
        // alert("Email failed to send.");
      });
  };

  useEffect(() => {
    const status = getStatusFromQuery(); // Extract status from query parameters
    if (status) {
      setStatusFilter(status); // Set the status filter if it exists in the URL
      fetchAppointmentsByQuery(status); // Fetch appointments based on the status from the URL
    } else {
      fetchAppointmentsByQuery(); // If no status, fetch all appointments
    }
  }, [location.search]); // Re-run when query params change

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Appointments
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">My Appointments</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="appointments">
                <div className="col-3 d-flex justify-content-end mb-3">
                  <select className="custom-select" value={statusFilter} onChange={handleFilterChange}>
                    <option value="">Select Appointment</option>
                    <option value="pending">Pending Appointment</option>
                    <option value="accepted">Accepted Appointment</option>
                    <option value="finished">Finished Appointment</option>
                    <option value="cancelled">Cancelled Appointment</option>
                    <option value="rejected">Rejected Appointment</option>
                    <option value="today">Today's Appointment</option>
                  </select>
                </div>

                {filteredAppointments.map((a) => (
                  <div className="appointment-list" key={a.id}>
                    <div className="profile-info-widget">
                      <div className="profile-det-info">
                        <h4>
                          <span
                            className={
                              a.status === "pending"
                                ? "badge badge-warning-soft fs-2"
                                : a.status === "accepted"
                                  ? "badge badge-info-soft fs-2"
                                  : a.status === "finished"
                                    ? "badge badge-success-soft fs-2"
                                    : "badge badge-danger-soft fs-2"
                            }
                          >
                            {a.status.charAt(0).toUpperCase() + a.status.slice(1)}
                          </span>
                        </h4>
                        <h3>

                          <Link to={"/appointmentPending/" + a.id}>{a.name}</Link>
                        </h3>
                        <div className="patient-details">
                          <h5>
                            <i className="far fa-clock" />{" "}
                            {a
                              ? (a.date instanceof Date
                                ? moment(a.date).format("MM/DD/YYYY HH:mm")
                                : moment(new Date(a.date)).format("MM/DD/YYYY ")) + ", " + a.time.start + " - " + a.time.end
                              : 'Invalid date'}
                            {/* {
                              a.date && a.date.toDate
                                ? moment(a.date.toDate()).format("MM/DD/YYYY") + " - " + a.time
                                : 'Invalid date or time'
                            }                           */}
                          </h5>
                          <h5>
                            <i className="fas fa-envelope" /> {a.email}
                          </h5>
                          <h5>
                            <i className="fas fa-calendar" />
                            {a.dob ? moment(a.dob).format("MM/DD/YYYY") // Change format as needed
                              :
                              a.dob
                                .toDate()
                                .toLocaleString("en-US")
                                .split(",")[0]

                            }
                          </h5>
                          <h5>
                            <i className="fas fa-phone" style={{ rotate: '90deg' }} /> {a.country_code}{" "} {a.phone}
                          </h5>
                          <h5 className="mb-0">
                            <i className="fas fa-list" /> {a.description}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="appointment-action">
                      <Link
                        to={"/appointmentPending/" + a.id}
                        className="btn btn-sm bg-info-light"
                        data-toggle="modal"
                        data-target="#appt_details"
                      >
                        <i className="far fa-eye" /> View
                      </Link>

                      {a.status == "pending" && (
                        <>

                          <button
                            className="btn btn-sm bg-success-light ml-2"
                            onClick={() => acceptAppointmentHandler(a.id, a.appId, a.email, a.name)} // Call the accept handler
                          >
                            Accept
                          </button>

                          {/* Reject Button */}
                          <button
                            className="btn btn-sm bg-danger-light ml-2"
                            onClick={() => handleReject(a.id, a.appId, a.email, a.name)} // Call the reject handler
                          >
                            Reject
                          </button>
                        </>
                      )}
                      {a.status == "accepted" && (
                        <>
                          <button
                            variant="danger"
                            className="me-2 btn btn-sm bg-success-light ml-2"
                            onClick={() => handleFinish(a.id, a.appId, a.email, a.name)}
                          >
                            Finish
                          </button>
                          <button
                            type="button"
                            className="me-2 btn btn-sm bg-danger-light ml-2"
                            onClick={() => handleCancel(a.id, a.appId, a.email, a.name)}
                          >
                            Cancel
                          </button>
                        </>
                      )}

                    </div>
                  </div>
                ))}

                {/* Pagination */}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(appointments.length / appointmentsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAppointments;
