import React, { useState, useEffect } from 'react'
import Sidebar from '../Partials/Sidebar/Sidebar'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { db } from '../../firebase/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import moment from 'moment';


const PatientAppointments = () => {
    const [appointments, setAppointments] = useState([]);
    const location = useLocation();

    // Extract the email from the query parameter
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const appointmentsRef = collection(db, 'appointments');
                const q = query(appointmentsRef, where('email', '==', email));
                const querySnapshot = await getDocs(q);

                const fetchedAppointments = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAppointments(fetchedAppointments);
            } catch (error) {
                console.error('Error fetching appointments:', error);
            }
        };

        if (email) fetchAppointments();
    }, [email]);


    return (
        <>
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        My Patients
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">My Patients</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9 dct-appoinment">
                            <div className="card">
                                <div className="card-body pt-0">
                                    <div className="user-tabs">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified flex-wrap">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    href="#pat_appointments"
                                                    data-toggle="tab"
                                                >
                                                    Appointments
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        {/* Appointment Tab */}
                                        <div id="pat_appointments" className="tab-pane fade show active">
                                            <div className="card card-table mb-0">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Doctor</th>
                                                                    <th>Appt Date</th>
                                                                    <th>Booking Date</th>
                                                                    <th>Email</th>
                                                                    <th>Contact</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {appointments.map((v) => (
                                                                    <tr key={v.id}>
                                                                        <td>
                                                                            <h2 className="table-avatar">
                                                                                <a
                                                                                    href="doctor-profile.html"
                                                                                    className="avatar avatar-sm mr-2"
                                                                                >
                                                                                    <img
                                                                                        className="avatar-img rounded-circle"
                                                                                        src="/assets/img/doctor.png"
                                                                                        alt="User Image"
                                                                                    />
                                                                                </a>
                                                                                <Link to={`/appointmentPending/${v.id}`} >
                                                                                    Dr. Sandhya Chinala <span>Psychiatrist, MD</span>
                                                                                </Link>
                                                                            </h2>
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                v.date
                                                                                    ? moment(v.date).format("MM/DD/YYYY") // Change format as needed
                                                                                    :
                                                                                    v.date
                                                                                        .toDate()
                                                                                        .toLocaleString("en-US")
                                                                                        .split(",")[0]

                                                                            }
                                                                            <span className="d-block text-info">{v.time.start +  "-" +  v.time.end }</span>
                                                                        </td>
                                                                        <td> {
                                                                            v.createdAt
                                                                                .toDate()
                                                                                .toLocaleString("en-US")
                                                                                .split(",")[0]
                                                                        } </td>
                                                                        <td>{v.email}</td>
                                                                        <td>{v.country_code + "" + v.phone}</td>
                                                                        <td>
                                                                            <span
                                                                                className={
                                                                                    v &&
                                                                                    (v.status == "pending"
                                                                                        ? `badge badge-pill bg-warning-light`
                                                                                        : v.status == "accepted"
                                                                                            ? `badge badge-pill bg-warning-info`
                                                                                            : v.status == "finished"
                                                                                                ? `badge badge-pill bg-success-light`
                                                                                                : `badge badge-pill bg-danger-light`)
                                                                                }
                                                                            >
                                                                                {v && v.status.charAt(0).toUpperCase() + v.status.slice(1)}
                                                                            </span>
                                                                        </td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default PatientAppointments