import { Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import './App.css';
import Login from "./components/login/Login";
import { ToastContainer, toast  } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MyAvailability from "./components/MyAvailability/MyAvailability";
import MyAppointments from "./components/MyAppointments/MyAppointments";
import AcceptedDetail from "./components/MyAppointments/AcceptedDetail";
import PendingDetail from "./components/MyAppointments/PendingDetail";
import FinishedDetail from "./components/MyAppointments/FinishedDetail";
import Chat from "./components/MyAppointments/Chat";
import Profile from "./components/Profile/Profile";
import EditProfile from "./components/Profile/EditProfile";
import PrivateRoute from "./components/AuthProvider/PrivateRoute";
import MyPatients from "./components/MyPatients/MyPatients";
import PatientAppointments from "./components/MyPatients/PatientAppointments";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword"
import BookAppointment from "./components/BookAppointment/BookAppointment";
import { auth } from "./firebase/firebase";
import { signOut } from "firebase/auth";
import Questions from "./components/Question/Questions";


function App() {
  const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes 
  let timeout;
  
  const resetTimeout = () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      signOut(auth).then(() => {
        toast.warn("Session expired. User signed out.", { position: 'top-center' });
        localStorage.clear()
        window.location.href = "/";
      });
    }, SESSION_TIMEOUT);
  };
  
  window.onload = resetTimeout;
  document.onmousemove = resetTimeout;
  document.onkeydown = resetTimeout;

  return (

    <main className="main-wrapper"  >
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      
      {/* <Header /> */}

        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route
            element={
              <PrivateRoute
              // userDetail={userDetail}
              // onProjectChange={handleProjectChange}
              // project={projectId}
              />
            }
          >
            {/* <Route exact path="/login" element={<Login />} /> */}
            <Route exact path="/book" element={<BookAppointment />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/myavailablity" element={<MyAvailability />} />
            <Route exact path="/myappointments" element={<MyAppointments />} />
            <Route exact path="/appointmentAccepted" element={<AcceptedDetail />} />
            <Route exact path="/appointmentPending/:appId" element={<PendingDetail />} />
            <Route exact path="/appointmentFinished" element={<FinishedDetail />} />
            <Route exact path="/chat" element={<Chat />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/questions" element={<Questions />} />
            <Route exact path="/editprofile" element={<EditProfile />} />
            <Route exact path="/mypatients" element={<MyPatients />} />
            <Route exact path="/patientAppointments" element={<PatientAppointments />} />
            {/* <Route exact path="/userchat" element={<UserChat />} /> */}
          </Route>
        </Routes>
        <ToastContainer />
     
    </main>
  );
}

export default App;
