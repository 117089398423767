import React, { useEffect, useState } from 'react'
import Sidebar from '../Partials/Sidebar/Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { db } from "../../firebase/firebase";
import {
    collection,
    getDocs,
    query,
    where,
    doc,
    updateDoc,
    serverTimestamp,
    Timestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase/firebase';



const MyPatients = () => {

    const [patients, setPatients] = useState([])
    const [imageUrls, setImageUrls] = useState({}); // Store image URLs

    const navigate = useNavigate()
    const patientsRef = collection(db, "appointments");


    const localHandler = (appointId) => {
        navigate({ pathname: '/chat', search: `?v=1&id=${appointId}`})
    }

    const appointmentHandler = (email) => {
        navigate(`/patientAppointments?email=${email}`);
    };

    async function fetchAppointmentsByQuery(statusArray) {
        try {
            const q = query(patientsRef, where("status", "in", statusArray));
            const querySnapshot = await getDocs(q);
    
            // Create a map to ensure unique emails
            const appointmentsMap = new Map();
    
            querySnapshot.docs.forEach((doc) => {
                const data = { id: doc.id, ...doc.data() };
                if (!appointmentsMap.has(data.email)) {
                    appointmentsMap.set(data.email, data); // Store only the first occurrence
                }
            });
    
            // Convert the map values to an array and set the state
            setPatients(Array.from(appointmentsMap.values()));
        } catch (error) {
            console.error("Error fetching appointments by query:", error);
        }
    }
    

    
    const getUserImageUrl = async (userId, gender) => {
        let imagePath = ``; // Default image path
        if (gender === 'male') {
            imagePath = `man.png`;
        } else if (gender === 'female') {
            imagePath = `woman.jpeg`;
        }

        try {
            const imageRef = ref(storage, imagePath); // Create reference to the image in Firebase Storage
            const url = await getDownloadURL(imageRef); // Get the download URL
            setImageUrls(prev => ({ ...prev, [userId]: url })); // Store the URL in state
        } catch (error) {
            console.error("Error fetching image from storage", error);
        }
    };

    useEffect(() => {
        patients.forEach(user => {
            if (!imageUrls[user.id]) {
                getUserImageUrl(user.id, user.gender);
            }
        });
    }, [patients]);

    const calculateAge = (dob) => {
        const dobDate = new Date(dob); // Convert the string to a Date object
        const today = new Date(); // Current date
    
        let age = today.getFullYear() - dobDate.getFullYear(); // Initial age calculation
        const monthDiff = today.getMonth() - dobDate.getMonth(); 
    
        // Adjust if the birth date hasn't occurred yet in the current year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
    
        return age;
    };
    

    useEffect(() => {
        fetchAppointmentsByQuery(["accepted", "finished"])
    }, [])


    

        {console.log(patients)}





    return (
        <>
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        My Patients
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">My Patients</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div className="row row-grid">
                                {patients && patients.map((v) => (
                                    <div className="col-md-6 col-lg-4 col-xl-3">
                                        <div className="card widget-profile pat-widget-profile" >
                                            <div className="card-body" >
                                                <div className="pro-widget-content">
                                                    <div className="profile-info-widget">
                                                        <div >
                                                            <a target='_blank' href={"/assets/img/default_avatar.png" } className="booking-doc-img">
                                                                {/* <img src="assets/img/patients/patient.jpg" alt="User Image" /> */}
                                                                <img src={"/assets/img/default_avatar.png"} alt="User" className="avatar-img rounded-circle" />
                                                            </a>
                                                            <div className="profile-det-info">
                                                                <h3>
                                                                    {v.name}
                                                                </h3>
                                                                <div className="patient-details">
                                                                    <h5>
                                                                        <b>Patient ID :</b> {v.id}
                                                                    </h5>
                                                                    {/* <h5 className="mb-0">
                                                                <i className="fas fa-map-marker-alt" /> Alabama, USA
                                                            </h5> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="patient-info" >
                                                    <ul>
                                                        <li>
                                                            Phone <span>{v.country_code + "" +v.phone}</span>
                                                        </li>
                                                        <li>
                                                            Age <span>{calculateAge(v.dob)} Years</span>
                                                        </li>
                                                    
                                                        {/* <li>
                                                            Date of Appointment <span>  {
                                                                v.date
                                                                    .toDate()
                                                                    .toLocaleString("en-US")
                                                                    .split(",")[0]
                                                            }</span>
                                                        </li> */}
                                                        <li className=''>
                                                            Email <span className='text-muted'>  {v.email}</span>
                                                        </li>
                                                        <li>
                                                            <div className='d-flex justify-content-between '>
                                                                <button className='btn btn-outline-info '  onClick={() =>localHandler(v.id)}>Chat</button>
                                                                <button className='btn btn-outline-info'  onClick={() => appointmentHandler(v.email)} >All Appointments</button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default MyPatients